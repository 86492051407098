import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgbDatepickerRange} from './datepicker-range.component';
import {fromNgbDateStructTo, Interval} from '../../../../utils/date.utils';
import {PeriodPipe} from '../../../../@theme/pipes/period.pipe';
import {breakpoints} from '../../../../@theme/breakpoints';
import {debounceTime, filter, fromEvent} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UnsubscribeComponent} from '../../../../@core/fc-component';

export interface DatepickerRangeFilterInterface {
    start_date?: Date | string;
    end_date?: Date | string;
    interval?: Interval;
    timezone?: string;
    comparison_period?: 'PREV_PERIOD' | 'PREV_YEAR';
    comparison_period_count?: number;
}

@Component({
    selector: 'datepicker-range-select',
    templateUrl: './datepicker-range-select.component.html',
    styleUrls: ['./datepicker-range-select.component.scss'],
})
export class DatepickerRangeSelectComponent extends UnsubscribeComponent {
    @Input() dateFilter: DatepickerRangeFilterInterface;
    @Output() dateFilterChange = new EventEmitter<DatepickerRangeFilterInterface>();
    @Output() closed = new EventEmitter();

    @Input() format: 'date' | 'dateTime' | 'ngbDate' | 'text' = 'date';
    @Input() initInterval = 'THIS_MONTH';
    @Input() needsTimezone = true;
    @Input() hasIntervals = false;
    @Input() hasComparison = false;
    @Input() hasComparisonMultiplier = true;
    @Input() emitOnCloseOnly = false;
    @Input() controlClass: string;
    @Input() placeholder: string;
    @Input() icon = true;
    @Input() clearable = false;

    @ViewChild(NgbDatepickerRange, {static: true}) dpRange: NgbDatepickerRange;
    @ViewChild('dropdownWrapper', {static: true}) dropdownWrapper: ElementRef;

    intervals: any[] = [
        // {key: 'HOUR', value: 'Hours'},
        {key: 'DAY', value: 'Days'},
        {key: 'WEEK', value: 'Weeks'},
        {key: 'MONTH', value: 'Months'},
        {key: 'YEAR', value: 'Years'},
    ];
    comparisonPeriods: any[] = [
        {key: 'PREV_PERIOD', value: 'Previous period'},
        {key: 'YEAR', value: 'Previous year'},
    ];
    periods: any[] = [
        {key: 'TODAY', value: 'Today'},
        {key: 'YESTERDAY', value: 'Yesterday'},
        {key: 'LAST_3_DAYS', value: 'Last 3 days'},
        {key: 'LAST_7_DAYS', value: 'Last 7 days'},
        {key: 'THIS_WEEK', value: 'This week'},
        {key: 'LAST_WEEK', value: 'Last week'},
        {key: 'LAST_30_DAYS', value: 'Last 30 days'},
        {key: 'THIS_MONTH', value: 'This month'},
        {key: 'LAST_MONTH', value: 'Last month'},
        {key: 'LAST_90_DAYS', value: 'Last 90 days'},
        {key: 'THIS_QUARTER', value: 'This quarter'},
        {key: 'LAST_QUARTER', value: 'Last quarter'},
        {key: 'LAST_183_DAYS', value: 'Last 183 days'},
        {key: 'LAST_365_DAYS', value: 'Last 365 days'},
        {key: 'THIS_YEAR', value: 'This year'},
        {key: 'LAST_YEAR', value: 'Last year'},
    ];
    dateCaption: string;
    datepickerOpen = false;
    displayMonths = 2;
    private _needsInitEmit = false;
    private _prevInterval?: string;
    updated = false;

    ngOnInit() {
        if (!this.dateFilter) this.dateFilter = {};

        if (this.hasIntervals) {
            if (!this.dateFilter.interval) this.dateFilter.interval = 'DAY';
            this._prevInterval = this.dateFilter.interval;
        }
        if (this.hasComparison) {
            if (!this.dateFilter.comparison_period) this.dateFilter.comparison_period = 'PREV_PERIOD';
            if (this.hasComparisonMultiplier && !this.dateFilter.comparison_period_count) this.dateFilter.comparison_period_count = 1;
        }
        if ((!this.dateFilter.start_date || !this.dateFilter.end_date) && this.initInterval && this.initInterval != 'NONE') {
            this._needsInitEmit = true;
        } else {
            setTimeout(() => {
                this.dateCaption = this._getDateCaption();
            }, 0);
        }
    }

    ngAfterViewInit() {
        fromEvent(window, 'resize')
            .pipe(
                takeUntil(this.destroy$),
                filter(() => this.datepickerOpen),
                debounceTime(100),
            )
            .subscribe(() => this._alignDropdownContent());
    }

    onDatesChange(dates: DatepickerRangeFilterInterface) {
        this.updated = true;
        this.dateFilter = {...(this.dateFilter || {}), ...dates};
        this.dateCaption = this._getDateCaption();
        this.autoSetInterval();
        if (this._needsInitEmit || !this.emitOnCloseOnly) {
            this.emitUpdate();
            this._needsInitEmit = false;
        }
    }

    onIntervalChange(): void {
        this.updated = true;
        this._prevInterval = this.dateFilter.interval;
        if (!this.emitOnCloseOnly) this.emitUpdate();
    }

    autoSetInterval() {
        if (this.dateFilter.interval) {
            const intervalTime = this.fromNgbDateStructTo.date(this.dpRange.toDate).getTime() - this.fromNgbDateStructTo.date(this.dpRange.fromDate).getTime();
            // if (intervalTime <= 86400000 * 2) this.smartInterval('HOUR', true);
            // else if (intervalTime <= 604800000 * 2) this.smartInterval('DAY');
            if (intervalTime <= 604800000 * 2) this.smartInterval('DAY');
            else if (intervalTime <= 2629746000 * 2) this.smartInterval('WEEK');
            else if (intervalTime <= 31556952000 * 2) this.smartInterval('MONTH');
            else this.smartInterval();
        }
    }

    smartInterval(key?: string, enableNext = false) {
        const index = key ? this.intervals.indexOf(this._getInterval(key)) : this.intervals.length - 1;
        this.intervals.forEach((x, i) => {
            x.disabled = i > index + (enableNext ? 1 : 0);
        });
        this.dateFilter.interval = this._prevInterval && this._getIntervalIndex(this._prevInterval) < index ? this._prevInterval : this.intervals[index].key;
    }

    open() {
        this._alignDropdownContent();
        this.displayMonths = window.innerWidth < breakpoints.md ? 1 : 2;
        this.datepickerOpen = true;
    }

    close() {
        if (this.updated) this.emitUpdate();
        this.closed.emit();
        this.datepickerOpen = false;
    }

    emitUpdate() {
        this.dateFilterChange.emit(this.dateFilter);
        this.updated = false;
    }

    private _getDateCaption(): string {
        const toString = this.fromNgbDateStructTo.text;
        return this.dpRange?.fromDate ?
            PeriodPipe.transform({
                start_date: toString(this.dpRange.fromDate),
                end_date: this.dpRange.toDate ? toString(this.dpRange.toDate) : '',
            }, {needsTimezone: false}) :
            '';
    }

    clear() {
        this.dpRange.fromDate = null;
        this.dpRange.toDate = null;
        this.onDatesChange({start_date: null, end_date: null, comparison_period: null, comparison_period_count: null, interval: null, timezone: null});
    }

    private _getInterval(key: string) {
        return this.intervals.find(x => x.key == key);
    }

    private _getIntervalIndex(key: string) {
        return this.intervals.indexOf(this._getInterval(key));
    }

    private _alignDropdownContent() {
        const dropdownWrapperRect: DOMRect = this.dropdownWrapper.nativeElement.getBoundingClientRect();
        const leftDistanceFromViewportLeft = dropdownWrapperRect.left;
        const rightDistanceFromViewportRight = window.innerWidth - dropdownWrapperRect.right;

        this.dropdownWrapper.nativeElement.classList[leftDistanceFromViewportLeft < rightDistanceFromViewportRight ? 'add' : 'remove']('dropdown-align-left');
    }

    get fromNgbDateStructTo() {
        return fromNgbDateStructTo(false, this.needsTimezone ? this.dpRange.timezone : null);
    }
}
